<template>
	<div class="container">
		<Header ref="headerBar"></Header>
		<div class="search-conWrap">
			<div class="breadcrumbs wrap">
				<img src="../../assets/images/icon-home.png" alt="" class="breadcrumbs-icon" />
				<a href="/">首页</a> > 联系方式
			</div>
			<div class="i-main wrap clearfix border-10">
				<div class="title-box">
					<span>联系方式</span>
				</div>
				<div class="content-box mt-40" v-html="content"></div>
			</div><!-- i-main end  -->

		</div><!-- search-conWrap end -->

		<div class="i-m9-wrap mt-60">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->

		<Footer></Footer>

		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>


	</div>
</template>

<script>
	
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "contact",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				content:'<p><strong>网站意见反馈：</strong></p><p><br></p><p>网站信息纠错、删除、反馈邮箱：<strong data-sider-select-id="df09c408-415b-4588-adaa-5f71d4b04934">1264383437@qq.com</strong></p><p>网站意见反馈客服QQ：<strong data-sider-select-id="bfdeb77e-afd9-4846-b7a8-fa7523cd38e2">1264383437</strong></p><p>请附上权利人证件、联系方式、链接、相关理由</p><p><span style="color: rgb(255, 0, 0);">品牌和商家的产品问题、售后问题请勿联系我们</span></p><p><br></p><p><strong>网站商务合作：</strong></p><p><br></p><p data-sider-select-id="7ffe2f65-e8de-4618-a2ab-475e5cd9a6af">品牌之家商务合作微信：<strong data-sider-select-id="2e5a0b95-df47-49c4-8911-6096aeade411">595926113</strong></p><p>请注明来意：品牌入驻、商家入驻、网站合作</p><p><span style="color: rgb(255, 0, 0);">产品购买、品牌加盟、品牌代理：请勿联系我们</span></p>'
			};
		},

		created() {},
		beforeUpdate() {},
		computed: {},
		mounted() {},
		methods: {
			
		},
	}
</script>

<style scoped>
	.i-main{
		padding: 25px 40px 60px 40px;
		box-sizing: border-box;
		border: 1px solid #ececec;
	}
	.title-box{
		height: 49px;
		border-bottom: 1px solid #eef3f5;
	}
	
	.title-box span{
		height: 47px;
		border-bottom: 2px solid #da0505;
		display: inline-block;
		position: relative;
		z-index: 3;
		bottom: -1px;
		font-size: 20px;
		color: #000;
		line-height: 30px;
	}
	
	.content-box{
		line-height: 30px;
	}
	
</style>